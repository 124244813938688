import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip, Switch, Tooltip } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';

const ScoreChip = withStyles(() => ({
    root: {
        marginLeft: '5px',
        color: 'white',
    },
}))(Chip);
const ScoreTab = withStyles(() => ({
    wrapper: {
        flexDirection: 'row',
    },
}))(Tab);
const AssessedSwitch = withStyles(() => ({
    root: {
        '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
            opacity: "0.5",
            backgroundColor: "#666",
        }
    }
}))(Switch);

const Label = ({ score, title, assessed, assessDimensionDisabled, toggleAssessedDimension }) => (
    <>
        {title}
        {score !== null && score !== undefined && (
            <ScoreChip variant="outlined" size="small" label={score} color="primary" component="span" />
        )}
        {assessed !== undefined && (
            <Tooltip title="Dimension assessed in the review ? (if not existing score is kept)">
                <AssessedSwitch
                    checked={assessed}
                    onClick={(e) => {
                        e.stopPropagation();
                        toggleAssessedDimension(e.target.checked);
                    }}
                    size="small"
                    color="primary"
                    disabled={assessDimensionDisabled}
                />
            </Tooltip>
        )}
    </>
);

Label.propTypes = {
    score: PropTypes.number,
    title: PropTypes.string,
    assessed: PropTypes.bool,
    assessDimensionDisabled: PropTypes.bool,
    toggleAssessedDimension: PropTypes.func,
};

const MraTabs = (props) => {
    const { dimensions, tabId, tabScores, onChangeTab, tabsRef, notAssessedDimensions, showAssessedSwitches = true, assessDimensionDisabled = false, toggleAssessedDimension } = props;

    const { t } = useTranslation();

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={tabId}
                className="tabs-menu"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={onChangeTab}
                {...(tabsRef ? { ref: tabsRef } : {})}
            >
                {dimensions.map((value, index) => (
                    <ScoreTab
                        key={index}
                        label={
                            <Label
                                score={tabScores[index]}
                                title={t(value.title)}
                                assessed={showAssessedSwitches ? !notAssessedDimensions?.includes(value['@id']) : undefined}
                                assessDimensionDisabled={assessDimensionDisabled}
                                toggleAssessedDimension={(checked) => toggleAssessedDimension(value['@id'], checked)}
                            />
                        }
                        style={notAssessedDimensions?.includes(value['@id']) ? { color: '#bbb'} : null}
                    />
                ))}
            </Tabs>
        </AppBar>
    );
};
MraTabs.propTypes = {
    dimensions: PropTypes.array,
    tabId: PropTypes.number,
    tabScores: PropTypes.objectOf(PropTypes.number),
    onChangeTab: PropTypes.func,
    tabsRef: PropTypes.any,
    showAssessedSwitches: PropTypes.bool,
    notAssessedDimensions : PropTypes.array,
    assessDimensionDisabled: PropTypes.bool,
    toggleAssessedDimension: PropTypes.func,
};

export default MraTabs;
