import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {APIResource} from '../../Services/APIResource/APIResource';
import {DimensionContent} from './Edit/DimensionContent';
import MraTabs from './Edit/MraTabs';
import MRA, {canEditMraScore, getUserRole, MRA_PROCESS, MRA_SCORES, MRA_STATUS} from '../../Services/MRA';
import Alert from '../../Services/Alert';
import Navigation from '../../Services/Navigation';
import {Header} from "../Header/Header";
import Http from '../../Services/Http';
import {getIdFromIri} from '../../Services/utils';
import {getParamByIri, getParamBySystemId} from '../../Store/ParameterStore';
import {withTranslation} from 'react-i18next';
import {CircularProgress} from '@material-ui/core';
import {OverviewPanelHorizontal} from './Display/OverviewPanelHorizontal';
import {ModalContent} from '../Modal/ModalContent';
import Modal from '../../Services/Modal';
import {DetachMraModal} from './Edit/DetachMraModal';
import {askConfirmationChange} from './Edit/AskConfirmationChangeModal';
import AppBarStore from '../../Store/AppBarStore';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import {NavigationButtons} from './Edit/NavigationButtons';

const ProcessHeader = (props) => {
    const {mraStatus, mraProcess} = props
    if (mraProcess !== MRA_PROCESS.LOD1_LOD2) return null

    let message = "In the 7 tabs below, please complete LoD1 score and score justification for every MRA subdimension. Please do not forget to submit your completed scores to LoD2 by clicking on the green button below. "
    if (mraStatus !== MRA_STATUS.ON_GOING) {
        message = "LoD2 has submitted scores that do not match with yours. You can update your scores if you want to. Please confirm your final scores before the Committee by clicking on the green button below. "
    }
    return <Header key="message" text={message}/>
}
ProcessHeader.propTypes = {
    /** valeur du systemId : */
    mraStatus: PropTypes.string,
    /** valeur du systemId : */
    mraProcess: PropTypes.string,
}

export const Edit = observer(
    withTranslation()(
        class Edit extends Component {
            constructor(props) {
                super(props);

                this.mraId = this.props.match.params.id;
                this.tabsRef = React.createRef(null);
                //this.clonedFrom = this.props.mraOptions.mraScores;
                /** @todo clonedFrom récupérer les scores du Mra en paramètre pour clonage
                 * et les passer à (après avoir enlevé les id) addMraScore */

                this.state = {
                    mra: null,
                    mraStatus: null,
                    mraProcess: null,
                    currentTabId: 0,
                    currentDimension: null,
                    showResults: false,
                    tabScores: {},
                    scoresMap: {},
                    role: '',
                    ready: false,
                    model: null,
                    review: null,
                    loadingReviewSave: false,
                    assignedValidator: null,
                    saving: false,
                    newMRALayout: false,
                    syncMras: [],
                    mraGroupLoading: false,
                    detachLoading: false,
                    loadingStatusSave: false,
                    // Pour DimensionContent
                    forceReload: true,
                    loadingDimension: true,
                    onlyReload: false,
                };
                this.requestConfigs = {}; // pour la performance de calc_mra_scores

                this.modelResource = new APIResource({
                    id: 'models',
                    name: 'Model',
                });
                this.parameterResource = new APIResource({
                    id: 'parameters',
                    name: 'Parameter',
                });
                this.mraResource = new APIResource({
                    id: 'mras',
                    name: 'Mra',
                });
                this.reviewResource = new APIResource({
                    id: 'reviews',
                    name: 'Review',
                });
                this.userResource = new APIResource({
                    id: 'users',
                    name: 'Users',
                });
                this.scopeResource = new APIResource({
                    id: 'scopes',
                    name: 'Scopes',
                });
                this.dimensionsResource = new APIResource({
                    id: 'mra_dimensions',
                    name: 'Dimensions',
                    instanceId: 'mra_edit_dimensions',
                });

            }

            componentDidMount() {
                this.mraId = this.props.match.params.id;
                this.loadMra(this.mraId);
            }

            componentDidUpdate(prevProps, prevState) {
                if (
                    prevProps.match.params.id !== this.props.match.params.id
                    || prevProps.readonly !== this.props.readonly
                ) {
                    this.mraId = this.props.match.params.id;
                    this.loadMra(this.mraId);
                }

                if (prevState.mra !== this.state.mra) {
                    AppBarStore.title = (
                        <Breadcrumb
                            entity={this.state.mra}
                            resource={this.mraResource}
                            relationalProperty={'model'}
                            resourcePath={'models'}
                        />
                    );

                    if (this.state.mra?.status) {
                        this.parameterResource.getItem(getIdFromIri(this.state.mra.status)).then(parameter => {
                            this.setState({mraStatus: parameter});
                        })
                    }

                    if (this.state.mra?.process) {
                        this.parameterResource.getItem(getIdFromIri(this.state.mra.process)).then(parameter => {
                            this.setState({
                                mraProcess: parameter,
                                newMRALayout: parameter?.systemId === MRA_PROCESS.LOD2,
                            });
                        })
                    }

                    if (this.state.mra?.model) {
                        this.modelResource.getItemFromResourcePath(this.state.mra.model, true).then((model) => {
                            this.setState({model});
                        });
                    }

                    if (this.state.mra?.review) {
                        this.reviewResource.getItemFromResourcePath(this.state.mra.review, true).then((review) => {
                            this.setState({review});
                            if (review.assignedValidator) {
                                this.setState({assignedValidator: getIdFromIri(review.assignedValidator)});
                            }
                        });
                    }

                    if (this.state.mra?.group) {
                        this.setState({mraGroupLoading: true});
                        MRA.getMrasSameGroup(this.state.mra).then(mras => {
                            this.setState({
                                syncMras: mras,
                                mraGroupLoading: false,
                            })
                        })
                    }

                    if (this.state.mra?.mraScores) {
                        // add the id to save
                        const mraScores = this.state.mra.mraScores.map((score) => {
                            const id = parseInt(score['@id'].split('/').pop(), 10);
                            return {...score, id};
                        });

                        this.addMraScore(mraScores);
                    }
                }

                if (prevState.model !== this.state.model) {
                    this.setState({role: getUserRole(this.state.model)});
                }

                if (
                    prevState.role !== this.state.role
                    || prevState.newMRALayout !== this.state.newMRALayout
                    || prevProps.readonly !== this.props.readonly
                ) {
                    this.redirectIfNeeded(this.state.role, this.state.newMRALayout);
                }

                if (
                    prevState.scoresMap !== this.state.scoresMap
                    || prevState.mraStatus !== this.state.mraStatus
                    || prevState.mraProcess !== this.state.mraProcess
                    || prevState.role !== this.state.role
                ) {
                    const dimensionsIris = Object.keys(this.state.scoresMap);
                    /**
                     * @todo vérifier si on a touché les scores ou les justifications,
                     * pour éviter de recalculer le score s'il y a juste une justif de changée
                     */
                    this.updateAllDimensionTabsScores([...new Set(dimensionsIris)].filter(d => d && d !== "undefined"));
                }
            }

            loadMra(mraId) {
                this.setState({ready: false});
                this.mraResource.getItem(mraId, true).then((mra) => {
                    this.dimensionsResource
                        .apiGetCollection({
                            page: 1,
                            rowsPerPage: 10000,
                            filters: {
                                dimension: getIdFromIri(mra.dimension), // NTX, BPCE ...
                            },
                        })
                        .then((value) => {
                            this.setState({
                                mra,
                                ready: true,
                                currentDimension: [...value].shift() || {},
                            });
                        });
                });
            }

            redirectIfNeeded(role, newMRALayout) {
                // si le role est LoD2, hors process LOD2, il faut rediriger vers
                // la page detail pour une saisie plus simpliste
                // ou si MRA importé, l'édition n'est pas possible
                // on redirige donc vers la page Détail
                // ou si le LoD1 ne peut pas saisir car ce n'est pas
                // son tour
                if (!newMRALayout && (role === 'LoD2' || this.state.mra.insertionFromImport)) {
                    if (this.getMraProcess() !== MRA_PROCESS.LOD2)
                        Alert.show({
                            message: `This view is used only by LoD1. You'll be redirected to the Detail View. `,
                            type: 'warning'
                        });
                    setTimeout(() => {
                        Navigation.router.history.push(`/resource/mras/${this.state.mra?.id || this.mraId}/detail`);
                    }, 3000);
                }

                if (role === 'LoD1' && this.getMraStatus() === MRA_STATUS.CONFLICT) {
                    Alert.show({
                        message: `MRA in conflict. You'll be redirected to the Detail View to edit and compare your scores.`,
                        type: 'warning'
                    });
                    setTimeout(() => {
                        Navigation.router.history.push(`/resource/mras/${this.state.mra?.id || this.mraId}/detail`);
                    }, 3000);
                }
                if (
                    role === 'LoD1' &&
                    !this.props.readonly &&
                    this.getMraProcess() !== MRA_PROCESS.LOD1 &&
                    this.getMraStatus() !== MRA_STATUS.ON_GOING &&
                    this.getMraStatus() !== MRA_STATUS.CONFLICT
                ) {
                    Alert.show({
                        message: `This MRA can't be edited as long as it hasn't been reviewed by LoD 2. You'll be redirected to the Detail View.`,
                        type: 'warning'
                    });
                    setTimeout(() => {
                        Navigation.router.history.push(`/resource/mras/${this.state.mra?.id || this.mraId}/detail`);
                    }, 3000);
                }
            }

            /**
             * Renvoie le systemId du process
             * @returns {string}
             */
            getMraProcess() {
                return this.state.mraProcess?.systemId;
            }

            /**
             * Renvoie le systemId du status
             * @returns {string}
             */
            getMraStatus() {
                return this.state.mraStatus?.systemId;
            }

            onDeleteMra() {
                if (this.state.syncMras && this.state.syncMras.length > 0) {
                    MRA.delete(
                        this.state.mra,
                        this.state.syncMras.map((mra) => mra.id)
                    );
                } else {
                    MRA.delete(this.state.mra);
                }
            }

            getDimensionTabId(dimension) {
                const dimensions = this.dimensionsResource.items;
                return dimension ? dimensions.findIndex((d) => dimension.id === d.id) : 0;
            }

            getCurrentTabId() {
                return this.getDimensionTabId(this.state.currentDimension);
            }

            getDimensionByTabId(tabId) {
                return this.dimensionsResource.items[tabId];
            }

            getCurrentMraScoresByDimension(dimension, readonly = false) {
                if (!dimension) return [];

                let allowedTypes = MRA.getAllowedTypes(this.state.role, this.getMraStatus(), this.getMraProcess());

                // Pour le calcul des scores des onglets notamment, on récupère tous les scores disponibles
                if (readonly) allowedTypes = Object.values(MRA_SCORES);

                const scores = MRA.scoresMapToMraScores(this.state.scoresMap, (s) => {
                    return (
                        allowedTypes.includes(getParamByIri(s.type).systemId) &&
                        dimension.mraSubdimensions.includes(s.mraSubdimension)
                    );
                });

                return scores;
            }

            /**
             * @deprecated
             */
            updateDimensionTabScores(dimensionIri) {
                let dimension = this.dimensionsResource.items.filter((d) => d['@id'] === dimensionIri).pop();
                let mraScoresValues = this.getCurrentMraScoresByDimension(dimension, true);

                let tabScores = {...this.state.tabScores};
                let tabId = this.getDimensionTabId(dimension);
                if (
                    dimension
                    && dimension.mraSubdimensions.length === mraScoresValues.length
                    /** désactivé @see https://app.asana.com/0/1173729351441775/1207908645363495/f */
                    // && this.isMraScoreValid(dimension, mraScoresValues)
                ) {
                    let p = this.calcMraScoreByDimension(dimension, mraScoresValues);
                    p.then((data) => {
                        this.setState((prevState) => {
                            tabScores = {...prevState.tabScores};
                            tabScores[tabId] = data;
                            return {...prevState, tabScores};
                        });
                    });
                } else {
                    this.setState((prevState) => {
                        tabScores = {...prevState.tabScores};
                        tabScores[tabId] = null;
                        return {...prevState, tabScores};
                    });
                }
            }

            /**
             * @param {Array<string>} dimensionsIris 
             */
            async updateAllDimensionTabsScores(dimensionsIris) {
                const mraScoresByDimension = dimensionsIris.reduce((carry, iri) => {
                    const dimension = this.dimensionsResource.items.filter((d) => d['@id'] === iri).pop();
                    if (!dimension) return carry;

                    const mraScoresValues = this.getCurrentMraScoresByDimension(dimension, true);
                    carry[iri] = mraScoresValues;
                    return carry;
                }, {});

                const dimensionsScores = await this.calcMraScoreByDimension(mraScoresByDimension);

                dimensionsIris.forEach(iri => {
                    const dimensionScore = dimensionsScores?.[iri] ?? null;
                    const tabId = this.getDimensionTabId({id: parseInt(getIdFromIri(iri))});
                    this.setState(({tabScores}) => {
                        return { tabScores: {
                            ...tabScores,
                            [tabId]: dimensionScore,
                        }};
                    })
                });
            }

            /**
             * Ajoute ou écrase le ou les mraScore(s) donné(s) à la map des scores du MRA actuel.
             *
             * @param {SubdimensionContent.MraScore} mraScore
             */
            addMraScore(mraScore) {
                let mraScores = !Array.isArray(mraScore) ? [mraScore] : mraScore;

                let dimensionsIris = [];
                let newScoresMap = this.state.scoresMap;

                mraScores.forEach((mraScore) => {
                    let {scoresMap, dimensionIri} = MRA.addMraScoreToScoresMap(
                        mraScore,
                        newScoresMap,
                        this.dimensionsResource.items
                    );
                    dimensionsIris.push(dimensionIri);
                    newScoresMap = scoresMap;
                });

                this.setState({scoresMap: {...this.state.scoresMap, ...newScoresMap}});
            }

            /**
             * Function pour récupérer le score d'une sous dimension.
             * On s'en sert pour initialiser les scores manquants automatiquement.
             *
             * PS: Nécessaire à partir du moment où l'on veut pouvoir revenir sur les onglets précédents.
             *
             * @param {Object} subdimension - Sous-dimension, il faudrait définir un typedef !
             * @param {boolean|null} canEdit - Teste si l'edition est possible. Ne teste pas si "canEdit" est null
             * @returns {MraScore}
             */
            getMraScores(subdimension, canEdit = null) {
                let scores = MRA.getMraScoresFromScoresMap(
                    this.state.scoresMap,
                    subdimension.mraDimension,
                    subdimension['@id'],
                    this.state.role,
                    this.getMraStatus(),
                    this.getMraProcess()
                );

                if (canEdit !== null) {
                    scores = canEdit
                        ? scores.filter((m) => canEditMraScore(m, this.state.role, this.getMraStatus(), this.getMraProcess()) 
                            && !(this.state.mra.notAssessedDimensions || []).includes(subdimension.mraDimension))
                        : scores.filter((m) => !canEditMraScore(m, this.state.role, this.getMraStatus(), this.getMraProcess())
                            || (this.state.mra.notAssessedDimensions || []).includes(subdimension.mraDimension)
                    );
                }
                return scores;
            }

            /**
             * Renvoie un booléen indiquant si le score de la dimension donnée est valide.
             *
             * @param {Object} dimension
             */
            isMraScoreValid(dimension, mraScoresValues = null) {
                let valid = false;

                let _mraScoresValues = mraScoresValues || this.getCurrentMraScoresByDimension(dimension);

                // check if all the subdimensions are defined.
                // there's one line by score so we need to make
                // the mraSubdimensions unique (Residual + Inherent on the same dimension)
                let uniqueMraSubdimensions = _mraScoresValues.reduce((values, value) => {
                    if (!values[value.mraSubdimension]) {
                        values[value.mraSubdimension] = value.mraSubdimension;
                    }
                    return values;
                }, []);

                if (dimension.mraSubdimensions.length === Object.keys(uniqueMraSubdimensions).length) {
                    valid = _mraScoresValues.reduce(
                        (p, c) => (MRA.isMraScoreValid(c, this.getMraProcess()) ? p : false),
                        true
                    );
                }
                return valid;
            }

            /**
             * Vérifie si tous les Mra Scores (pour chaque dimension) sont valides.
             */
            isMraScoresValid() {
                const dimensions = this.dimensionsResource.items;
                let valid = true;
                let err = [];
                dimensions.forEach((dim) => {
                    if (!this.isMraScoreValid(dim)) {
                        valid = false;
                        err.push(dim);
                    }
                });

                return {valid, err};
            }

            /**
             * Appel au backend pour obtenir tous les scores de toutes les dimensions d'un coup.
             * 
             * @param {Object.<string, Array>} scoresByDimension 
             * @returns {Promise.<Object.<string, number>>}
             */
            async calcMraScoreByDimension(scoresByDimension) {
                const existingRequest = this.requestConfigs?.['__all__'];
                if (existingRequest) existingRequest.cancel('NoAlert');

                const requestConfig = Http.getRequest();
                this.requestConfigs['__all__'] = requestConfig;
                let data = await Http.post('mras/calc_dimensions_scores', {
                    scoresByDimension,
                    dimensionScoringAlgorithm: this.state?.mra?.dimensionScoringAlgorithm || ''
                }, requestConfig);
                delete this.requestConfigs['__all__'];
                return data['hydra:member'];
            }

            /**
             * Appel au backend pour calculer le score d'une dimension
             * en envoyant la liste des scores des sous-dimensions
             * @deprecated
             * @param {Object} dimension
             * @returns {number}
             */
            async calcMraScoreForDimension(dimension, mraScoresValues = null) {
                let res = 0;
                if (this.state.scoresMap !== null && this.state.scoresMap !== undefined) {
                    let _mraScoresValues = mraScoresValues || this.getCurrentMraScoresByDimension(dimension);

                    const parentDimension = getParamByIri(dimension.dimension);

                    if (
                        _mraScoresValues !== undefined &&
                        dimension.mraSubdimensions.length === _mraScoresValues.length
                    ) {
                        const existingRequest = this.requestConfigs?.[dimension.id];
                        if (existingRequest) existingRequest.cancel('NoAlert');

                        const requestConfig = Http.getRequest();
                        this.requestConfigs[dimension.id] = requestConfig;
                        let data = await Http.post('mras/calc_mra_scores', {
                            scores: _mraScoresValues,
                            dimension: parentDimension.systemId,
                            dimensionScoringAlgorithm: this.state?.mra?.dimensionScoringAlgorithm || ''
                        }, requestConfig);
                        delete this.requestConfigs[dimension.id];
                        res = data['hydra:member']['value'];
                    }
                }
                return res;
            }

            /**
             * Returns the next status if authorized
             * @return {[type]} [description]
             */
            getNextStatus(nextStatus, mra) {
                if (this.getMraStatus() === MRA_STATUS.CONFLICT) {
                    return getParamBySystemId(this.getMraStatus());
                } else {
                    // if scores are matching and LOD2 already submitted
                    // then we can fast forward to the next status
                    if (MRA.areScoresMatching(mra.mraScores) && mra.lod2Submit) {
                        return getParamBySystemId(MRA_STATUS.FINALIZED)
                    }
                }

                return nextStatus;
            }

            sendLod2NotificationOnConflict(toUser) {
                let content = `MRA in conflict: new LOD1 scores have been submitted for review`;
                if (this.state.review) {
                    content = `MRA in conflict: new LOD1 scores have been submitted for review: ${this.state.review.title} (id: ${this.state.review.id})`;
                }
                Http.post('notifications/new', {
                    toUser,
                    content,
                    link: `/resource/mras/${this.mraId}/detail`,
                    byEmail: true,
                });
            }

            /**
             * DEBUT DES METHODES PARTAGEES/COPIEES AVEC "Mra/Detail.js" POUR LES
             * MISES A JOUR DE L'OVERVIEW PANEL.
             */

            onDetachSuccess(mra) {
                if (mra.id === this.state.mra.id) {
                    this.setState({syncMras: [], mra, detachLoading: false});
                } else {
                    /** @todo il faudrait aussi probablement détacher le mra actuel s'il n'a plus de sync mras */
                    this.setState(prevState => ({
                        syncMras: prevState.syncMras.filter(syncMra => syncMra.id !== mra.id),
                        mra: {
                            ...prevState.mra,
                            group: prevState.syncMras.filter(syncMra => syncMra.id !== mra.id).length === 0 ? null : prevState.mra.group
                        },
                        detachLoading: false
                    }));
                }
            }

            onDetach(mra) {
                if (mra.group) {
                    Modal.open({
                        title: 'Confirm the model detachment from this MRA',
                        content: <DetachMraModal
                            mra={mra}
                            detachLoading={this.state.detachLoading}
                            setDetachLoading={(val) => this.setState({detachLoading: val})}
                            onSuccess={this.onDetachSuccess.bind(this)}
                        />,
                    });
                }
            }

            async saveReviewChange(reviewIriPath) {
                this.setState({loadingReviewSave: true});

                const {success, payload} = await MRA.simpleSave({
                    ...this.state.mra,
                    review: reviewIriPath,
                });
                if (!success) {
                    Alert.show({
                        message: `An error occurred while changing the review for the MRA`,
                        type: 'error',
                    });
                } else {
                    Alert.show({
                        message: `Successfully changed the review attached to the MRA`,
                        type: 'success',
                    });

                    this.setState({mra: payload, loadingReviewSave: false});
                }
            }

            async saveStatusChange(status) {
                this.setState({loadingStatusSave: true});

                const {success, payload} = await MRA.simpleSave({
                    ...this.state.mra,
                    status,
                });
                if (!success) {
                    Alert.show({
                        message: `An error occurred while changing the status for the MRA`,
                        type: 'error',
                    });
                } else {
                    Alert.show({
                        message: `Successfully changed the MRA status`,
                        type: 'success',
                    });

                    this.setState({mra: payload, loadingStatusSave: false});
                }
            }

            askConfirmationBeforeCallbackIfGrouped(callback) {
                if (this.state.mra.group) {
                    askConfirmationChange(() => callback());
                } else {
                    callback();
                }
            }

            /**
             * FIN DES METHODES ~= 'MRA/Detail.js'
             */

            /**
             * Sauvegarde le MRA en cours et ses scores.
             * @returns {Promise<{ success: boolean, payload: any }>}
             */
            async save() {
                const mraScores = MRA.scoresMapToMraScores(this.state.scoresMap, (s) => s.id !== undefined).map((s) => ({
                    ...s,
                    id: s.id ? '/api/mra_scores/' + s.id : null,
                }));
                return MRA.simpleSave({
                    ...this.state.mra,
                    mraScores
                });
            }

            /**
             * Changer le MRA de Statut.
             */
            onDimensionSubmit(nextStatus) {
                this.setState({loadingDimension: true});
                const {valid, err} = this.isMraScoresValid();
                if (!valid) {
                    /**
                     * @todo on pourrait afficher les tabs en erreur ?
                     */
                    let invalidDimensions = err.map((d) => d.title).join('\n');
                    Alert.show({
                        message: `Every subdimension of :\n ${invalidDimensions} \n should have a justification.`,
                        type: 'warning'
                    });
                    // add 1 second of delay
                    setTimeout(() => {
                        this.save().then((m) => {
                            if (!m) {
                                Alert.show({
                                    message: 'An error occurred saving this MRA.',
                                    type: 'warning'
                                });
                            } else {
                                Alert.show({
                                    message: 'This MRA has been saved.',
                                    type: "success"
                                });
                            }
                        });
                    }, 7000);
                    this.setState({loadingDimension: false});
                    return false;
                } else {
                    // On sauvegarde la demande actuelle avant de passer à la nouvelle étape
                    this.save().then((res) => {
                        const {success, payload} = res
                        let mra = null
                        if (success) {
                            mra = payload
                        } else {
                            Alert.show({
                                message: 'Unable to save this MRA',
                                type: 'warning'
                            });
                            return
                        }

                        // On lance le passage à l'étape suivante :
                        const status = this.getNextStatus(nextStatus, mra);
                        MRA.submit(this.mraId, status, this.state.role).then((entity) => {
                            if (!entity) {
                                Alert.show({
                                    message: 'An error occurred submitting this MRA.',
                                    type: 'warning'
                                });
                            } else {
                                // send a notification to LOD2 if the status is conflict
                                if (status === MRA_STATUS.CONFLICT) {
                                    if (this.state.assignedValidator) {
                                        this.sendLod2NotificationOnConflict(this.state.assignedValidator);
                                    }
                                }
                                Alert.show({
                                    message: 'This MRA has been successfully submitted.',
                                    type: "success"
                                });

                                // on recharge le modèle avant d'afficher le détail
                                this.mraResource.getItem(entity.id, true).then(() => {
                                    this.props.history.push('/resource/mras/' + entity.id + '/detail');
                                })
                            }
                        });
                    });
                }
            }

            /**
             * Sauvegarder le MRA
             */
            async onDimensionSave() {
                this.setState({saving: true});

                const {success, payload} = await this.save();
                if (!success) {
                    Alert.show({
                        message: `An error occurred saving this MRA: ${JSON.stringify(payload)}`,
                        type: 'warning'
                    });
                } else {
                    Alert.show({
                        message: 'This MRA has been successfully saved.',
                        type: "success"
                    });
                    this.setState({mra: payload});
                }

                this.setState({saving: false});

                return true;
            }

            changeTab(event, tabId) {
                let delay = new Date();
                delay = delay.setSeconds(delay.getSeconds() - 1);
                if (!this.lastTabChange || this.lastTabChange <= delay) {
                    this.lastTabChange = new Date();
                    this.setState({
                        currentDimension: this.getDimensionByTabId(tabId),
                    });
                }
            }

            toggleAssessedDimension(dimensionIri, assessed) {
                MRA.toggleAssessedDimension(this.state.mra, dimensionIri, assessed).then(mra => this.setState({ mra }));
            }


            render() {
                return (
                    <>
                        {this.state.ready && (
                            <div className="mra-process-container">
                                <ProcessHeader mraStatus={this.getMraStatus()} mraProcess={this.getMraProcess()}/>
                                {this.state.newMRALayout && this.state.model && <OverviewPanelHorizontal
                                    mra={this.state.mra}
                                    mraModel={this.state.model}
                                    mraProcess={this.state.mraProcess}
                                    mraStatus={this.state.mraStatus}
                                    syncMras={this.state.syncMras}
                                    onDetach={this.onDetach.bind(this)}
                                    detachLoading={this.state.detachLoading}
                                    mraGroupLoading={this.state.mraGroupLoading}
                                    dimensions={this.dimensionsResource.items}
                                    review={this.state.review}
                                    onReviewChange={this.saveReviewChange.bind(this)}
                                    loadingReviewSave={this.state.loadingReviewSave}
                                    onOpenValidation={this.askConfirmationBeforeCallbackIfGrouped.bind(this)}
                                    loadingStatusSave={this.state.loadingStatusSave}
                                    onStatusChange={(status) => this.askConfirmationBeforeCallbackIfGrouped(() => this.saveStatusChange(status))}
                                />}
                                <div className="tabs">
                                    <MraTabs
                                        dimensions={this.dimensionsResource.items}
                                        tabId={this.getCurrentTabId()}
                                        tabScores={this.state.tabScores}
                                        onChangeTab={this.changeTab.bind(this)}
                                        tabsRef={this.tabsRef}
                                        showAssessedSwitches={this.state.review}
                                        notAssessedDimensions={this.state.mra.notAssessedDimensions || []}
                                        assessDimensionDisabled={this.props.readonly || MRA.isInactive({status: {systemId: this.getMraStatus()}})}
                                        toggleAssessedDimension={this.toggleAssessedDimension.bind(this)}
                                    />
                                </div>
                                <div className="content">
                                    {this.state.currentDimension && <DimensionContent
                                        dimension={this.state.currentDimension}
                                        loadingDimension={this.state.loadingDimension}
                                        setLoadingDimension={loadingDimension => this.setState({loadingDimension})}
                                        setOnlyReload={onlyReload => this.setState({onlyReload})}
                                        forceReload={this.state.forceReload}
                                        addMraScore={this.addMraScore.bind(this)}
                                        getMraScores={this.getMraScores.bind(this)}
                                        mra={this.state.mra}
                                        tabsRef={this.tabsRef}
                                        readonly={this.props.readonly}
                                    />}
                                    <NavigationButtons
                                        mra={this.state.mra}
                                        syncMras={this.state.syncMras}
                                        userRole={this.state.role}
                                        readonly={this.props.readonly}
                                        onSave={this.onDimensionSave.bind(this)}
                                        saving={this.state.saving}
                                        loadingDimension={this.state.loadingDimension}
                                        onSubmit={this.onDimensionSubmit.bind(this)}
                                        onlyReload={this.state.onlyReload}
                                        setForceReload={forceReload => this.setState({forceReload})}
                                    />
                                </div>
                            </div>
                        )}
                        {!this.state.ready && (
                            <ModalContent style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <CircularProgress/>
                            </ModalContent>
                        )}
                    </>
                );
            }
        }
    )
);
